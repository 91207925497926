import React from "react"
import { graphql, Link } from "gatsby"

import Main from "../components/CaseStudies/Main"


import Layout from '../components/layout'
// import Introduction from "../components/Services/Introduction"
import Services from '../components/Home/Services'
import Breadcrumb from "../components/Global/Breadcrumb"



function ServicesTemplate(props) {
    let data = props.data.wpgraphql;

    function getBreadcrumb (){
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url:  `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/" }`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
            url:  `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "" }${props.pageContext.currentPage}`
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://enimeris.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://enimeris.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.page.servicesPageAcf.featuredImage} title={data.page.servicesPageAcf.title} />
                    </section> */}
                    <section>
                        <Breadcrumb list={ getBreadcrumb() } />
                    </section>
                    <section>
                        <Main backImg={data.page.servicesPageAcf.featuredImage} content={data.page.servicesPageAcf.introductionParagraph} title={data.page.servicesPageAcf.title} />
                    </section>
                    <section>
                        <Services data={{ services: data.serviceCategories.edges }} stylez={{paddingTop:"0px"}} />
                    </section>
                </div>
            </Layout>
        </div>
    )
}

export default ServicesTemplate

export const pageQuery = graphql`
    query GET_SERVICES($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    servicesPageAcf{
                    title
                    introductionParagraph
                    featuredImage{
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality: 80){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
            serviceCategories(where: {language: $language}) {
                ...ServicesFragment
            }
        }
    }
`
